@keyframes fadeIn {
    0% {
        right: -240px;
    }
    100% {
        right: 0;
    }
}

@keyframes fadeOut {
    0% {
        right: 0;
    }
    100% {
        right: -240px;
    }
}

.flash-message-wrapper {
    position: fixed;
    right: 0px;
    top: 80px;
    width: 240px;
}
.fade-wrap,
.flash-message {
    position: relative;
    width: 240px;
}
.flash-message:hover {
    cursor: pointer;
}
.flash-active {
    right: 0;
}
.login-image-background {
    /*background-image: url("/images/login-main.jpg");*/
    background-image: url("/images/truck-home.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.login-background-black {
    background-color: rgba(0, 0, 0, .7);
    width: 100%;
}

.login-logo {
    width: 200px;
    max-width: 50% !important;
}

.logo-text {
    font-size: 26px;
    margin: 0 auto;
}
.logo-text:hover {
    text-decoration: none;
}

.logo-text:hover span {
    color: #cccccc !important;
}

.sidebar-footer .pull-left {
    width: 50% !important;
}

.vertical-bottom {
    vertical-align: bottom !important;
}

/******************
  NOTIFICATION MSG
 *****************/
.notification-message-wrapper {
    position: absolute;
    right: 0;
    top: 0;
}

.notification-message-wrapper .alert {
    -webkit-animation: moveNotification .5s; /* Safari 4.0 - 8.0 */
    animation: moveNotification .5s;
}

@-webkit-keyframes moveNotification {
    from {right: -200px;}
    to {right: 0;}
}

/* Standard syntax */
@keyframes moveNotification {
    from {right: -200px;}
    to {right: 0;}
}

/************
  LOAD TABLE
 ************/
.load-table th  {
    font-size: 18px !important
}

.load-table td {
    font-size: 16px !important;
}

/************
    LOADER
************/
.action-container.loader-page {
    position: absolute;
    left: 50%;
    top: 45%;
    z-index: 10;
}


/************
  DIALOG
 ************/
.dialog-background {
    position: fixed;
    background-color: rgba(0, 0, 0, .8);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 100;
    -webkit-animation: moveDialog .5s; /* Safari 4.0 - 8.0 */
    animation: moveDialog .5s;
}

.dialog-container {
    width: 80%;
    max-height: 90vh;
    margin-left: 10%;
    margin-top: 5vh;
    background-color: #ffffff;
    position: relative;
    padding: 20px 30px;
    overflow: auto;
}

.dialog-container.small-dialog {
    width: 40%;
    margin-left: 30%;
}

.dialog-container.large-dialog {
    width: 80%;
    height: 80vh;
    margin-left: 10%;
}

.dialog-container .dialog-title-wrapper {
    margin-bottom: 40px;
}

.dialog-container .dialog-title-wrapper .dialog-main-title {
    color: #232a31;
    font-size: 24px;
    line-height: 24px;
}

.dialog-container .dialog-title-wrapper .dialog-second-title {
    text-transform: uppercase;
    font-size: 12px;
    color: #8392a5;
    letter-spacing: 0.3px;
}

.dialog-container .close-dialog {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.dialog-container .close-dialog i {
    color: #000000;
    font-size: 20px;
    font-weight: 900;
}

.dialog-container .dialog-text-wrapper {
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 400;
    color: #212529;
}

.dialog-container .dialog-section-title {
    color: #8392a5;
    font-size: 14px;
    letter-spacing: 0.3px;
}

@-webkit-keyframes moveDialog {
    from {top: -100vh;}
    to {top: 0;}
}

/* Standard syntax */
@keyframes moveDialog {
    from {top: -100vh;}
    to {top: 0;}
}

/*************
    BUTTONS
 *************/
.icon-btn {
    cursor: pointer;
    color: #ffffff;
    text-decoration: none;
}
.icon-btn:hover {
    color: #8392a5;
}

.control-btn {
    font-size: 25px;
}

/****************
    PAGINATION
 ***************/

.page-link {
    color: #1a2532 !important;
}

.page-item.active-element .page-link {
    color: #007bff !important;
}

.page-link:hover {
    color: #007bff !important;
}

.pagination-text {
    padding-top: 7px;
    margin-left: 10px;
}

/****************
    DATEPICKER
 ***************/
.react-datepicker-wrapper {
    display: block;
    width: 100%;
}
.display-block {
    display: block;
}

/****************
 NO RESULT LABEL
 ***************/
.no-result-label {
    text-align: center;
    font-size: 26px;
    margin-top: 20px;
}

/*************
    HELPER
 *************/
.has-cursor {
    cursor: pointer;
}

.display-none {
    display: none !important;
}
.inline-block {
    display: inline-block;
}
.bottom-margin {
    margin-bottom: 16px;
}
.link-like-element, .link-like-element-table {
    cursor: pointer;
    color: #0a6aa1;
    -webkit-user-select: none; /* Chrome/Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+ */
    -o-user-select: none;
    user-select: none;
    text-decoration: none;
}

.link-like-element:hover {
    color: #0a001f;
    text-decoration: none;
}

.link-like-element-table:hover {
    color: #ffffff;
}

.input-group:nth-child(2) {
    padding-left: 0 !important;
}
/*****************
    ADDRESS MAP
*****************/
.dialog-container .addressMap {
    width: 100%;
    height: 72vh;
    margin-top: 30px;
    vertical-align: top;
    margin-bottom: 30px;
}

/*************
    Route Details
 *************/
#route_details_map {
    width: 60%;
    display: inline-block;
    vertical-align: top;
    padding-top: 21px;
}
#route_details {
    width: 39%;
    display: inline-block;
    vertical-align: top;
}
.google-map {
    width: 100%;
    height: 80vh;
    vertical-align: top;
}

.inline-pad {
    margin: 6px;
    display: inline-block;
}
.route-details-state {
    padding: 0 16px;
}
.route-details-state > div {
    display: inline-block;
    width: 49%;
}
.route-tracks {
    padding: 0 16px 0px 16px;
}
.route-details-data-margin {
    margin: 8px;
}
.route-details-data-wrap > div {
    display: inline-block;
    width: 49%;
}
.route-track-link {
    border: 1px solid #999;
}
.route-track-link > div {
    margin-right: 8px;
    padding: 4px;
}
.route-details-pagination {
    justify-content: flex-start !important;
    margin-top: 8px;
    margin-bottom: 8px !important;
}
.no-data-collected {
    text-align: center;
    margin-top: 120px;
}
.route-buttons {
    margin-left: 4px;
    margin-top: 6px;
}
.fpx {
    width: 140px;
    border-right: 1px solid #999;
    text-align: left;
}

.logo-img > img {
    margin-right: 8px;
    margin-bottom: 4px;
}

.danger-route-icon {
    display: inline-block;
    margin-left: 5px;
    color: red;
}

input[type=checkbox].custom-checkbox {
    width: 30px;
    height: 30px;
    margin-top: 4px;
    margin-left: 5px;
}
span.bold {
    font-weight: bold;
}

.general-btn:hover {
    background-color: #008AD8;
    color: #ffffff;
}

.general-btn.active {
    background-color: #008AD8;
    color: #ffffff;
}

.general-btn-icon {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    margin: 0 10px;
}

.general-btn-icon.disabled {
    cursor: not-allowed;
}

.general-btn-icon img {

}

.general-btn-icon .active {
    display: none;
}

.general-btn-icon:hover .not-active {
    display: none;
}

.general-btn-icon:hover .active {
    display: inline-block;
}

.attr-field {
    display: inline-block !important;
    margin: 10px 10px 0 0 !important;
    width: 30% !important;
}

.attr-val-medium {
    display: inline-block !important;
    margin: 10px 0px 0 0 !important;
    width: 30% !important;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.attr-val-large {
    display: inline-block !important;
    margin: 10px 0px 0 0 !important;
    width: 50% !important;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.125);
}

.attr-val-small {
    display: inline-block !important;
    margin: 10px 0px 0 0 !important;
    width: 18% !important;
    padding: 6px;
    color: #008AD8
}

.min-height-220 {
    min-height: 220px;
}

.timeline {
    margin: 0px !important;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-top-10 {
    margin-top: 10px;
}

.dialog-container .minimize-dialog {
    position: absolute;
    right: 50px;
    top: 20px;
    cursor: pointer;
}

.minimized-address-book {
    position: fixed;
    width: 135px;
    height: 51px;
    top: 14px;
    right: 155px;
    z-index: 100;
    animation: moveDialog .5s;
}

.minimized-address-book > a,
.minimized > a {
    display: block;
    padding: 5px;
    width: 100%;
    background-color: rgba(0, 0, 0, .8);
    color: #fff !important;
    cursor: pointer;
    text-align: center;
}

.minimized {
    position: fixed;
    width: 135px;
    height: 51px;
    top: 14px;
    right: 14px;
    z-index: 100;
    animation: moveDialog .5s;
}

.form-control-select {
    display: block;
    width: 1%;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
}

.invalid-feedback {
    display: block !important;
}

.page-sidebar-fixed .page-content.retracted {
    margin-left: 50px;
}
.page-content.retracted {
    min-height: 60px;
    vertical-align: bottom;
    width: calc(100% - 50px);
    float: right;
}

.page-sidebar-small {
    background: #2d353e;
    width: 50px;
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    height: 100% !important;
    padding: 14px 7px;
}